import { addWeeks } from "date-fns";

import { companyId, apiLanguage as language } from "@chef/constants";
import {
  getDateFromWeekYear,
  getWeek,
  getWeekYearSortableNumber,
  getYear,
} from "@chef/helpers";

import {
  CalendarDocument,
  CalendarQuery,
  CalendarQueryVariables,
  ProductsByCategoriesDocument,
  ProductsByCategoriesQuery,
  ProductsByCategoriesQueryVariables,
  TimeblocksByRangeQuery,
} from "./generated";
import {
  createCustomCacheForQueries,
  ICustomQueryInterface,
} from "./customCache";

export const tags = {
  billing: "billing",
  paymentCard: "paymentCard",
  address: "address",
  preference: "preference",
  defaultBasket: "defaultBasket",
  discount: "discount",
  giftcard: "giftcard",
  consent: "consent",
  order: "order",
  timeblock: "timeblock",
  calendar: "calendar",
  preselectedProducts: "preselectedProducts",
  contactPreferences: "contactPreferences",
  recipe: "recipe",
  externalPartner: "externalPartner",
  userInfo: "userInfo",
  loyaltyLevelsByCompany: "loyaltyLevelsByCompany",
  favorites: "favorites",
  preselectorBasket: "preselectorBasket",
  preselectorPreview: "preselectorPreview",
};

export type Tags = keyof typeof tags;

const apiWithCustomCache = createCustomCacheForQueries([
  {
    queryName: "productsByCategories",
    queryDocument: ProductsByCategoriesDocument,

    createDataFromCache: (args, cache) => {
      const result: ProductsByCategoriesQuery["productsByCategories"] = [];

      for (const categoryId of args.categoryIds) {
        const key = `${categoryId}-${args.week}-${args.year}`;
        const cachedValue = cache[key];
        if (!cachedValue) {
          return null;
        }

        result.push(
          cachedValue as ProductsByCategoriesQuery["productsByCategories"][0],
        );
      }

      return {
        productsByCategories: result,
      };
    },

    createObjectCacheValue: (args, { productsByCategories }) => {
      const cache: {
        [cache: string]: ProductsByCategoriesQuery["productsByCategories"][0];
      } = {};

      for (const pbc of productsByCategories) {
        const key = `${pbc.productCategoryId}-${args.week}-${args.year}`;
        cache[key] = pbc;
      }

      return cache;
    },
  } as ICustomQueryInterface<
    ProductsByCategoriesQuery,
    ProductsByCategoriesQueryVariables
  >,

  {
    queryName: "calendar",
    queryDocument: CalendarDocument,

    createDataFromCache: (args, cache) => {
      const result: CalendarQuery["calendar"] = [];

      const startDate = getDateFromWeekYear({
        week: args.week,
        year: args.year,
      });

      for (let range = 0; range < (args.range || 0); range++) {
        const currentDate = addWeeks(startDate, range);

        const week = getWeek(currentDate);
        const year = getYear(currentDate);

        const key = `${week}-${year}`;
        const cachedValue = cache[key];
        if (!cachedValue) {
          return null;
        }

        result.push(cachedValue as CalendarQuery["calendar"][0]);
      }

      return {
        calendar: result,
      };
    },

    createObjectCacheValue: (args, { calendar }) => {
      const cache: { [cache: string]: CalendarQuery["calendar"][0] } = {};

      for (const cal of calendar) {
        const key = `${cal.week}-${cal.year}`;
        cache[key] = cal;
      }

      return cache;
    },
  } as ICustomQueryInterface<CalendarQuery, CalendarQueryVariables>,
]);

export const api = apiWithCustomCache.enhanceEndpoints({
  addTagTypes: Object.keys(tags),
  endpoints: {
    billing: {
      providesTags: [
        tags.billing,
        tags.paymentCard,
        tags.address,
        tags.preference,
        tags.defaultBasket,
        tags.discount,
        tags.giftcard,
        tags.consent,
        tags.contactPreferences,
      ],
    },

    order: { providesTags: [tags.order] },
    orders: { providesTags: [tags.order] },

    timeblocks: { providesTags: [tags.timeblock], extraOptions: { companyId } },
    timeblocksByRange: {
      providesTags: [tags.timeblock],
      extraOptions: { companyId },
      transformResponse: (response: TimeblocksByRangeQuery) => {
        response.timeblocksByRange.sort(
          (a, b) => getWeekYearSortableNumber(a) - getWeekYearSortableNumber(b),
        );

        return response;
      },
    },

    calendar: { providesTags: [tags.calendar] },
    preferences: {
      providesTags: [tags.preference],
      extraOptions: { companyId },
    },

    preselectedProductsForWeek: {
      providesTags: [tags.preselectedProducts],
      extraOptions: { companyId, language },
    },
    preselectorPreview: {
      providesTags: [tags.preselectorPreview],
      extraOptions: { companyId },
    },

    recipesByProductIdList: {
      extraOptions: { language, companyId },
      providesTags: [tags.recipe],
    },
    recipeAndSteps: { extraOptions: { language } },
    recipesByTaxonomyIdList: { extraOptions: { language, companyId } },
    recipesAgreementRatings: { providesTags: [tags.recipe] },
    recipesAgreementFavorites: { providesTags: [tags.favorites] },

    taxonomyRelations: { extraOptions: { companyId, language } },

    productsByCategories: { extraOptions: { companyId } },
    productCategories: { extraOptions: { companyId } },

    pickAndMix: { extraOptions: { companyId, language } },

    defaultCoupon: { extraOptions: { companyId } },

    billingNorwegian: { providesTags: [tags.externalPartner] },

    userInfo: { providesTags: [tags.userInfo] },

    availablePaymentPartners: { extraOptions: { companyId } },

    loyaltyLevelsByCompany: {
      extraOptions: { companyId },
    },

    recipesByIngredientCategories: {
      extraOptions: { companyId, language },
    },

    ingredientCategoriesByCompany: {
      extraOptions: { companyId, language },
    },

    recipesByIds: { extraOptions: { language } },
  },
});
